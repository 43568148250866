// import api from '@/services/api';
import axios from 'axios';
import AppError from '@/utils/appError';

const urlBase = process.env.VUE_APP_BASE_FOCUS_LEAD_PLANTAO_URL;
const apiKey = process.env.VUE_APP_FOCUS_LEAD_PLANTAO_KEY;

const buscarPlantoes = async ({ CPFCorretor }) => {
  try {
    const body = { CPFCorretor };
    const config = { headers: { "X-Gravitee-Api-Key": apiKey } };
    const { data } = await axios.post(`${urlBase}/busca-plantao-corretor`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const mudarStatus = async (body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": apiKey } };
    const { data } = await axios.post(`${urlBase}/mudar-status-corretor`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  buscarPlantoes,
  mudarStatus,
};
